<template>
   <div>
      <main>
         <!-- formulario area start -->
         <section class="formulario__area pt-50 pb-50 grey-bg" :style="'backgroundImage: url('+ layout.banner +')'">
            <div class="container">
               <div class="row">
                  <div class="col-lg-8 offset-lg-2 container-formulario">
                     <div class="row m-0">
                        <div class="col-12 px-1" v-if="layout.titulo != null && String(layout.titulo).trim().length > 0">
                           <div class="section__head mb-30">
                              <div class="w-100 section__title mb-1">
                                 <h3 class="text-center">{{ layout.titulo }}</h3>
                              </div>
                           </div>
                        </div>
                        <div class="col-12 px-1 mb-20" v-if="layout.descricao != null && String(layout.descricao).trim().length > 0">
                           <p class="postagem-html" v-html="layout.descricao"></p>
                        </div>
                        <div class="col-12 px-1 mb-10">
                           <label>Nome *</label>
                           <input type="text" class="form-control" v-model="formulario.nome" maxlength="200" />
                        </div>
                        <div class="col-12 px-1 mb-10">
                           <label>Email *</label>
                           <input type="email" class="form-control" v-model="formulario.email" maxlength="200" />
                        </div>
                        <div class="col-6 px-1 mb-10">
                           <label>CPF *</label>
                           <input type="text" class="form-control" v-model="formulario.cpf" maxlength="200" />
                        </div>
                        <div class="col-6 px-1 mb-10">
                           <label>Telefone (Com DDD) *</label>
                           <input type="text" class="form-control" v-model="formulario.telefone" maxlength="200" />
                        </div>
                        <div class="col-12 px-1 mb-10">
                           <label>Endereço *</label>
                           <input type="text" class="form-control" v-model="formulario.endereco.rua" maxlength="100" />
                        </div>
                        <div class="col-7 px-1 mb-10">
                           <label>Bairro</label>
                           <input type="text" class="form-control" v-model="formulario.endereco.bairro" maxlength="50" />
                        </div>
                        <div class="col-5 px-1 mb-10">
                           <label>CEP *</label>
                           <input type="text" class="form-control" v-model="formulario.endereco.cep" maxlength="10" />
                        </div>
                        <div class="col-7 px-1 mb-10">
                           <label>Cidade *</label>
                           <input type="text" class="form-control" v-model="formulario.endereco.cidade" maxlength="100" />
                        </div>
                        <div class="col-5 px-1 mb-10">
                           <label>UF *</label>
                           <input type="text" class="form-control" v-model="formulario.endereco.estado" maxlength="2" />
                        </div>
                        <div class="col-6 px-1 mb-10">
                           <label>Nome da loja onde adquiriu o equipamento *</label>
                           <input type="text" class="form-control" v-model="formulario.nomeLoja" maxlength="200" />
                        </div>
                        <div class="col-6 px-1 mb-10">
                           <label>Nome do vendedor que o atendeu na loja *</label>
                           <input type="email" class="form-control" v-model="formulario.nomeVendedor" maxlength="200" />
                        </div>
                        <div class="col-6 px-1 mb-10">
                           <label>Nº série do equipamento *</label>
                           <input type="email" class="form-control" v-model="formulario.serieEquipamento" maxlength="200" />
                        </div>
                        <div class="col-6 px-1 mb-20">
                           <label>Carregar nota fiscal</label>
                           <input type="file" class="form-control" ref="notaAnexo" />
                        </div>
                        <div class="col-12 px-1 text-center">
                           <button class="t-y-btn t-y-btn-2" @click="enviarFormulario">Enviar formulário <i class="fas fa-chevron-right font-12 ms-2"></i></button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </main>

      <footer>
         <div class="footer__area footer-bg-2">
            <div class="footer__copyright footer__copyright-2 py-3 footer-bg-2">
               <div class="container custom-container">
                  <div class="footer__copyright-text footer__copyright-text-2">
                     <div class="row justify-content-between align-items-center">
                        <div class="col-sm-3 text-center text-sm-start mb-3 mb-sm-0">
                           <router-link to="/"><img src="@/assets/cliente/img/logo/logo-white.png" alt="logo"></router-link>
                        </div>
                        <div class="col-sm-9 text-center text-sm-end">
                           <p>Copyright © <router-link to="/">CCM do Brasil.</router-link> Todos os direitos reservados. By <a href="https://agenciaoneway.com/" target="_blank"> OneWay.</a></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
   </div>
</template>

<script>

import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Formulário',
   data : function () {
      return {
         layout: {'id': null, 'titulo': null, 'descricao': null, 'banner': null},
         formulario: {
            'nome': '', 'email': '', 'telefone': '', 'cpf': '', 'nomeLoja': '', 'nomeVendedor': '', 'serieEquipamento': '', 
            'endereco': {'rua': '', 'bairro': '', 'cep': '', 'cidade': '', 'estado': ''}
         }
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   watch: { 
      '$route.params.id': {
         handler: function () {
            this.getFormulario()
         },
         deep: true,
         immediate: true
      }
   },
   methods: {
      getFormulario : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getNoticia',
            params: {
					id: this.$route.params.id
				}
			}).then(response => {
            this.layout = response.data

            if (this.layout.tipo != 'Formulário') {
               router.push('/')
            }
         }).catch((error) => {
				if (error.response != undefined) {
               if (error.response.status == 400) {
                  router.push('/').catch(function(){})
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
            window.scrollTo(0, 0)
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      enviarFormulario : function () {
         if (this.formulario.nome == null || String(this.formulario.nome).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nome inválido!'
            });

            return
         } else if (this.formulario.email == null || String(this.formulario.email).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email inválido!'
            });

            return
         } else if (this.formulario.telefone == null || String(this.formulario.telefone).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Telefone inválido!'
            });

            return
         } else if (this.formulario.endereco.rua == null || String(this.formulario.endereco.rua).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Rua inválida!'
            });

            return
         } else if (this.formulario.endereco.cidade == null || String(this.formulario.endereco.cidade).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Cidade inválida!'
            });

            return
         } else if (this.formulario.endereco.estado == null || String(this.formulario.endereco.estado).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Estado inválido!'
            });

            return
         } else if (this.formulario.endereco.cep == null || String(this.formulario.endereco.cep).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'CEP inválido!'
            });

            return
         } else if (this.formulario.cpf == null || String(this.formulario.cpf).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'CPF inválido!'
            });

            return
         } else if (this.formulario.nomeLoja == null || String(this.formulario.nomeLoja).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Loja inválida!'
            });

            return
         } else if (this.formulario.nomeVendedor == null || String(this.formulario.nomeVendedor).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Vendedor inválido!'
            });

            return
         } else if (this.formulario.serieEquipamento == null || String(this.formulario.serieEquipamento).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nº série inválido!'
            });

            return
         }

         let dataForm = new FormData();
         this.$store.dispatch('alternarTelaCarregamento', true)

         for (let file of this.$refs.notaAnexo.files) {
            dataForm.append('file', file, String(file.name).replace(/[^\w\s.]/gi, ''));
         }
         
         dataForm.append('info', JSON.stringify(this.formulario));

         this.$axios({
            method: 'post',
            url: this.urlRest +'site/cadastrarCliente',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(() => {
            this.formulario = {
               'nome': '', 'email': '', 'telefone': '', 'cpf': '', 'nomeLoja': '', 'nomeVendedor': '', 'serieEquipamento': '', 
               'endereco': {'rua': '', 'bairro': '', 'cep': '', 'cidade': '', 'estado': ''}
            }

            Swal.fire({
               icon: 'success',
               title: 'Formulário enviado!'

            }).then(() => {
               router.push('/')
            });
         }).catch((error) => {
            if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   }
}

</script>

<style scoped>

.formulario__area {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed
}

.container-formulario input {
	padding: 8px 12px;
   margin: 0;
   height: auto;
}

.container-formulario label {
   color: #222;
   font-size: 11.5px;
}

.container-formulario textarea {
   resize: none;
}

.footer__area img {
	width: 120px;
}

</style>